// src/pages/Blog.js
import React from 'react';

const Blog = () => {
  return (
    <div>
      <main>
        <section>
          <h2>...Coming soon</h2>
        </section>
      </main>
    </div>
  );
};

export default Blog;
