// src/components/RecipeCardList.js
import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { scrollChevron } from './utils/scrollUtils';

const RecipeCardList = ({ recipeArray, displayCustomRecipe, title, loadingRecipe, generateRecipes, filters, listClass }) => {

    // State for quips
    const [currentQuipIndex, setCurrentQuipIndex] = useState(0);
    const funnyQuips = [
        "Chef is dreaming up a meal!",
        "Chef is cooking the meal to make sure it's quick and delicious!",
        "Almost there...",
        "Chef is taste testing your recipe!",
        "Just a moment longer!",
        "Chef took a quick snooze and will be right back.",
        "Good things take time!"
    ];

    const runBasedOnIngredientsConst = true;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentQuipIndex((prevIndex) => (prevIndex + 1) % funnyQuips.length);
        }, 3000); // Change quip every 3 seconds

        return () => clearInterval(interval);
    }, [funnyQuips.length]);


    return (
        <div className="card pt-3 m-2 rounded-0 border-0">
            <h1 className="h3 mb-2 mx-2 ls-tight">{title}</h1>
            <div className="position-relative">
                <i className="bi bi-chevron-left position-absolute" style={{ left: 15, top: '50%', transform: 'translateY(-50%)', zIndex: 1, cursor: 'pointer', fontSize: '24px' }} onClick={() => scrollChevron("left", `.${listClass}`)}></i>
                <div className= {`d-flex flex-nowrap overflow-auto ${listClass}`}>
                    {recipeArray.map(recipe => (
                        <div key={recipe.id} className="col-6 col-sm-4 col-md-3 col-lg-2 mx-2">
                            <div className="d-flex flex-column border rounded overflow-hidden" style={{ height: '450px' }}>
                                <img src={recipe.imageUrl ? recipe.imageUrl : "https://via.placeholder.com/150"} alt="Recipe" className="w-100" style={{ objectFit: 'cover' }} />
                                <div className="p-3">
                                    <h4>{recipe.name}</h4>
                                    <p>{recipe.description}</p>
                                </div>
                                <div className="px-3 pb-3 d-flex mt-auto">
                                    <span className="rounded-pill badge border bg-light text-dark"><i className="fa-solid fa-timer"></i> {recipe.time || "N/A"}</span>
                                    <span className="rounded-pill badge border mx-2 bg-light text-dark"><i className="fa-solid fa-earth-asia"></i> {recipe.cuisine || "General"}</span>
                                </div>
                            </div>
                        </div>
                    ))}

                    {displayCustomRecipe && (
                        <div className="col-6 col-sm-4 col-md-3 col-lg-2 mx-2">
                            <div className="d-flex flex-column border rounded overflow-hidden" style={{ height: '450px' }}>
                                {loadingRecipe ? (
                                    <div className="btn btn-primary width-100 d-flex justify-content-center align-items-center" style={{ aspectRatio: '1 / 1' }}>
                                        <Spinner animation="border" />
                                        <div className="text-center mt-2 d-flex justify-content-center align-items-center" style={{ position: 'relative', height: '40px', width: '100%' }}>
                                            {funnyQuips.map((text, index) => (
                                                <span 
                                                    key={index} 
                                                    className={`quips ${index === currentQuipIndex ? "fade-in" : "fade-out"}`} 
                                                    style={{ position: 'absolute', opacity: index === currentQuipIndex ? 1 : 0, transition: 'opacity 1s' }}>
                                                    {text}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <Button 
                                        variant="primary" 
                                        className="width-100 d-flex align-items-center justify-content-center" 
                                        style={{ aspectRatio: '1 / 1'}} 
                                        onClick={() => generateRecipes(runBasedOnIngredientsConst)}
                                    >
                                        <h4 className="text-white">Generate More Recipes</h4>
                                    </Button>
                                )}

                                <div className="p-3">
                                    <h4>Your Custom Recipe</h4>
                                    <p>Generate additional recipes based on your Preferences & Ingredients</p>
                                </div>
                                <div className="px-3 pb-3 d-flex mt-auto">
                                    <span className="rounded-pill badge border bg-light text-dark"><i className="fa-solid fa-timer"></i> {filters.time.length > 0 ? filters.time.join(", ") : "Any Length"}</span>
                                    <span className="rounded-pill badge border mx-2 bg-light text-dark"><i className="fa-solid fa-earth-asia"></i> {filters.cuisine.length > 0 ? filters.cuisine.join(", ") : "Any Cuisine"}</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <i className="bi bi-chevron-right position-absolute" style={{ right: 15, top: '50%', transform: 'translateY(-50%)', zIndex: 1, cursor: 'pointer', fontSize: '24px' }} onClick={() => scrollChevron("right", `.${listClass}`)}></i>
            </div>
        </div>
    );
};

export default RecipeCardList;
