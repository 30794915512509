// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import lazychef_logo from '../../assets/lazychef_logo.png'; 

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light px-0 py-3">
      <div className="container-xl max-w-screen-xl">
        {/* Logo */}
        <Link className="navbar-brand" to="/">
          <img src={lazychef_logo} className="h-12" alt="LazyChef Logo" />
        </Link>
        {/* Navbar toggle */}
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* Collapse */}
        <div className="collapse navbar-collapse" id="navbarCollapse">
          {/* Nav */}
          <div className="navbar-nav mx-lg-auto">
            <Link className="nav-item nav-link" to="/">Home</Link>
            <Link className="nav-item nav-link" to="/blog">Blog</Link>
          </div>
          {/* Right navigation */}
          <div className="navbar-nav ms-lg-4">
            <Link className="nav-item nav-link" to="/signin">Sign in</Link>
          </div>
          {/* Action */}
          <div className="d-flex align-items-lg-center mt-3 mt-lg-0">
            <Link to="/register" className="btn btn-sm btn-neutral w-full w-lg-auto">
              Register
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
