import React, { useState, useEffect } from 'react';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider } from "firebase/auth";
import { auth } from '../../firebase/firebase';

const Login = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [code, setCode] = useState('');
    const [verificationId, setVerificationId] = useState(null);
    const [message, setMessage] = useState('');


    useEffect(() => {
        // Set up reCAPTCHA verifier
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                size: 'invisible',  // This makes the reCAPTCHA invisible to the user
                callback: (response) => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                }
            }, auth);
            window.recaptchaVerifier.render().catch(error => {
                console.error("Recaptcha error:", error);
            });
        }
    }, [auth]);

    const requestOTP = () => {
        if (phoneNumber.trim()) {
            const appVerifier = window.recaptchaVerifier;
            signInWithPhoneNumber(auth, phoneNumber, appVerifier)
                .then(confirmationResult => {
                    setVerificationId(confirmationResult.verificationId);
                    setMessage("Verification code sent.");
                })
                .catch(error => {
                    console.error("Error sending verification code:", error);
                    setMessage("Failed to send verification code.");
                });
        }
    };

    const verifyCode = () => {
        const credential = PhoneAuthProvider.credential(verificationId, code);
        auth.signInWithCredential(credential)
            .then(() => {
                setMessage("Phone number verified and user logged in.");
            })
            .catch(error => {
                console.error("Error verifying code:", error);
                setMessage("Error verifying code.");
            });
    };

    return (
        <div>
            <h1>Login</h1>
            <div id="recaptcha-container"></div>
            <div>
                <input
                    type="text"
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <button onClick={requestOTP}>Send Verification Code</button>
            </div>
            <div>
                <input
                    type="text"
                    placeholder="Enter verification code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                />
                <button onClick={verifyCode} disabled={!verificationId}>Verify Code</button>
            </div>
            {message && <p>{message}</p>}
        </div>
    );
};

export default Login;
