import React from 'react';

const Header = (props) => {
    return (
    <header className="bg-surface-primary border-bottom pt-6 sticky-top">
        <div className="container-fluid">
            <div className="mb-npx">
                <div className="row align-items-center mb-4">
                    <div className="col-12 d-flex align-items-center justify-content-between flex-wrap">
                        <div className="d-flex align-items-center">
                            <h1 className="h2 mb-0 ls-tight">{props.title}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    );
};

export default Header;
