import React, { useState, useEffect } from 'react';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, signInWithCredential } from "firebase/auth";

const Registration = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [code, setCode] = useState('');
    const [verificationId, setVerificationId] = useState(null);
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [message, setMessage] = useState('');

    const auth = getAuth();
    console.log(auth);

    useEffect(() => {
        // Set up reCAPTCHA verifier
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                size: 'invisible',  // This makes the reCAPTCHA invisible to the user
                callback: (response) => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                }
            });
            window.recaptchaVerifier.render().catch(error => {
                console.error("Recaptcha error:", error);
            });
        }
    }, [auth]);

    const requestOTP = () => {
        if (phoneNumber.trim()) {
            const appVerifier = window.recaptchaVerifier;
            signInWithPhoneNumber(auth, phoneNumber, appVerifier)
                .then(confirmationResult => {
                    setVerificationId(confirmationResult.verificationId);
                    setMessage("Verification code sent.");
                })
                .catch(error => {
                    console.error("Error sending verification code:", error);
                    setMessage("Failed to send verification code.");
                });
        }
    };

    const verifyCode = () => {
        const credential = PhoneAuthProvider.credential(verificationId, code);
        console.log(credential);
        signInWithCredential(auth, credential)
            .then(() => {
                setMessage("Phone number verified. Please complete your registration.");
                // Here, you can redirect the user to another page or ask for more details

                // Shruti note: Note that the registration happens at this stage!
            })
            .catch(error => {
                console.error("Error verifying code:", error);
                setMessage("Error verifying code.");
            });
    };

    const completeRegistration = () => {
        // Here, you might save the additional information to your database
        console.log('Additional Info:', additionalInfo);
        setMessage("Registration complete!");
        // Redirect to home page or dashboard
    };

    return (
        <div>
            <h1>Registration</h1>
            <div id="recaptcha-container"></div>
            <div>
                <input
                    type="text"
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <button onClick={requestOTP}>Send Verification Code</button>
            </div>
            <div>
                <input
                    type="text"
                    placeholder="Enter verification code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                />
                <button onClick={verifyCode} disabled={!verificationId}>Verify Code</button>
            </div>
            {message && <p>{message}</p>}
            {verificationId && (
                <div>
                    <input
                        type="text"
                        placeholder="Enter additional information"
                        value={additionalInfo}
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                    />
                    <button onClick={completeRegistration}>Complete Registration</button>
                </div>
            )}
        </div>
    );
};

export default Registration;
