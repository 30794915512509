import React, { useState, useEffect } from 'react';
import { RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, signInWithCredential, linkWithCredential } from "firebase/auth";
import AppNavbar from '../../components/NavHeaders/AppNavbar';
import { auth, db } from  '../../firebase/firebase';
import { doc, getDoc, setDoc } from "firebase/firestore";


const Profile = () => {
    
    // Recaptcha + turn anonymous account into real account
    const [phoneNumber, setPhoneNumber] = useState('');
    const [code, setCode] = useState('');
    const [verificationId, setVerificationId] = useState(null);
    const [isVerificationSent, setIsVerificationSent] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    //Edit form info
    const [name, setName] = useState('');

    // Fix refresh issues
    const [loading, setLoading] = useState(true);

    console.log(auth);


    useEffect(() => {
        // Set up reCAPTCHA verifier
        if (!window.recaptchaVerifier) {
            console.log("ReCAPTCHA not initialized - now initializing");
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                size: 'invisible',  // This makes the reCAPTCHA invisible to the user
                callback: (response) => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    console.log("Recaptcha solved");
                },
                'expired-callback': () => {
                    console.log('reCAPTCHA expired');
                    // Response expired. Ask user to solve reCAPTCHA again.
                    // ...
                }
            });
            window.recaptchaVerifier.render().catch(error => {
                console.error("Recaptcha error:", error);
            });
        }

        // Listen for authentication state changes
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                console.log("User is logged in:", user);
                setPhoneNumber(user.phoneNumber || '');

                const userRef = doc(db, "users", user.uid);
                getDoc(userRef).then(docSnap => {
                    if (docSnap.exists()) {
                        setName(docSnap.data().name || '');
                    } else {
                        console.log("No such document!");
                    }
                    setLoading(false);
                });
            } else {
                console.log("No user is logged in");
                setLoading(false);
            }
        });

        return () => unsubscribe(); // Clean up the subscription

    }, []);

    // loading state
    if (loading) {
        return <div>Loading...</div>; // Render loading state while data is fetching
    }

    // OTP and registration
    const requestOTP = () => {
        console.log('OTP started');
        if (phoneNumber.trim()) {
            const appVerifier = window.recaptchaVerifier;
            signInWithPhoneNumber(auth, phoneNumber, appVerifier)
                .then(confirmationResult => {
                    setVerificationId(confirmationResult.verificationId);
                    setIsVerificationSent(true);
                    setMessage("Verification code sent.");
                    console.log('Verification code sent');
                })
                .catch(error => {
                    console.error("Error sending verification code:", error);
                    setMessage("Failed to send verification code.");
                    console.log('Verification code NOT sent');
                    setIsVerificationSent(false);
                });
        }
    };

    const verifyCode = async () => {

        setError('');
        try {
            const credential = PhoneAuthProvider.credential(verificationId, code);
            const user = auth.currentUser;

            if (user.isAnonymous) {
                await linkWithCredential(user, credential);
                console.log('Linked with credential');
            } else {
                await signInWithCredential(auth, credential);
                console.log('New User created')
            }
            setError('');
            alert('You now have an account and will be able to login with your phone number!');
        } catch (error) {
            console.error("Error verifying code:", error);
            setError('Invalid verification code. Please try again.');
        }
    };

    // Edit User profile
    const updateUserProfile = async () => {
        if (auth.currentUser) {
            const userRef = doc(db, "users", auth.currentUser.uid);
            await setDoc(userRef, { name }, { merge: true });
            setMessage("Profile updated successfully.");
        }
    };

    return (
        <div className="d-flex flex-column flex-md-row h-lg-full bg-surface-secondary">
            <AppNavbar />
            <div className="h-screen bg-surface-primary flex-grow-1 d-flex flex-column overflow-y-auto">
                <header className="bg-surface-primary border-bottom pt-6 sticky-top">
                    <div className="container-fluid">
                        <div className="mb-npx">
                            <div className="row align-items-center mb-4">
                                <div className="col-12 d-flex align-items-center justify-content-between flex-wrap">
                                    <h1 className="h2 mb-0 ls-tight">Profile</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="card pt-3 m-4 rounded-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="card border-0">
                                <div className="card-header">
                                    <h5 className="mb-0">Profile Information</h5>
                                </div>
                                <div id="recaptcha-container"></div>
                                <div className="card-body">
                                    {auth.currentUser && auth.currentUser.isAnonymous ? (
                                        <div>
                                            <h6>You are not logged in. To make an account, please enter your number below.</h6>
                                            <div className="mb-3">
                                                <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="phoneNumber"
                                                    value={phoneNumber}
                                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                                    placeholder="+1234567890"
                                                />
                                            </div>
                                            <button className="btn btn-primary" onClick={requestOTP}>Send Verification Code</button>
                                            {isVerificationSent && (
                                                <div className="mt-3">
                                                    <label htmlFor="verificationCode" className="form-label">Verification Code</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="verificationCode"
                                                        value={code}
                                                        onChange={(e) => setCode(e.target.value)}
                                                        placeholder="Enter verification code"
                                                    />
                                                    <button className="btn btn-primary mt-3" onClick={verifyCode}>Verify and Create Profile</button>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="mb-3">
                                                <label htmlFor="name" className="form-label">Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    placeholder="Enter your name"
                                                />
                                            </div>
                                            
                                            <div className="mb-3">
                                                <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control disabled"
                                                    id="phoneNumber"
                                                    value={phoneNumber}
                                                    readOnly
                                                    style={{ backgroundColor: '#e9ecef', color: '#495057', cursor: 'not-allowed' }}
                                                />
                                                <p className="text-muted text-sm"><i>To change your number, please contact support@lazychef.io.</i></p>
                                                <button className="btn btn-primary mt-3" onClick={updateUserProfile}>Update Profile</button>
                                            </div>



                                        </div>
                                    )}
                                    {message && <div className="alert alert-info">{message}</div>}
                                    {error && <div className="alert alert-danger">{error}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Profile;
