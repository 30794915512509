import React, { useState, useEffect } from 'react';
import { db, auth } from '../../firebase/firebase';
import { collection, getDocs, query, where } from "firebase/firestore";
import AppNavbar from '../../components/NavHeaders/AppNavbar';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';

import ToggleButton from 'react-bootstrap/ToggleButton';

// UI Element Imports
import Header from '../../components/NavHeaders/AppHeader'
import RecipeCardList from '../../components/RecipeCardList';

import { scrollChevron } from '../../components/utils/scrollUtils';


// import generateRecipes from '../../components/GenerateRecipes';

const HomePage = () => {
    const [recipes, setRecipes] = useState([]);
    const [availableRecipes, setAvailableRecipes] = useState([]);
    const [userIngredients, setUserIngredients] = useState([]);
    const [allRecipes, setAllRecipes] = useState([]); // Store all recipes fetched from Firestore

    // modal for load message
    const [loading, setLoading] = useState(true);

    // modal for recipe gen load message
    const [loadingRecipe, setLoadingRecipe] = useState(false);

    // modal for filters
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filters, setFilters] = useState({
        time: [],
        cuisine: [],
        foodPreferences: [],
        mealType: []
      });
    

    // Example options, you should replace these with real options from your data or state
    const timeOptions = ['Super Quick', 'Quick', 'Normal', 'Long', 'Very Long'];
    const cuisineOptions = ['Indian', 'Chinese', 'Thai', 'American', 'Italian', 'Mexican', 'French', 'Japanese', 'Mediterranean', 'African', 'Caribbean'];
    const foodPreferencesOptions = ['Vegan', 'Vegetarian', 'Gluten-Free', 'Percetarian', 'Dairy-Free', 'Paleo', 'Keto'];
    const mealTypeOptions = ['Breakfast', 'Lunch', 'Dinner', 'Snack', 'Dessert'];

    const filterCategories = [
        {
            key: 'time',
            title: 'Time',
            options: timeOptions
        },
        {
            key: 'cuisine',
            title: 'Cuisine',
            options: cuisineOptions
        },
        {
            key: 'foodPreferences',
            title: 'Food Preferences',
            options: foodPreferencesOptions
        },
        {
            key: 'mealType',
            title: 'Meal Type',
            options: mealTypeOptions
        }
    ];

    

    useEffect(() => {
        const handleAuthStateChange = async (user) => {
            if (user) {
                try {
                    await Promise.all([fetchUserIngredients(user), fetchRecipes()]);
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };
    
        const unsubscribe = auth.onAuthStateChanged(handleAuthStateChange);
    
        return () => unsubscribe();
    }, []);
    
    useEffect(() => {
        if (userIngredients.length > 0 && allRecipes.length > 0) {

            // console.log("we are running the filter now!");
            // console.log(filters);
            filterRecipes();
            // console.log(recipes);
        }
        // eslint-disable-next-line
    }, [userIngredients, allRecipes, filters]); // Dependency on userIngredients and allRecipes

    useEffect(() => {
        if (userIngredients.length > 0 && allRecipes.length > 0) {
            filterRecipesByIngredients();
        }
        // eslint-disable-next-line
    }, [recipes]);


    // Get ingredients & recipes
    const fetchUserIngredients = async (user) => {
        console.log("fetching user ingredients");
        if (user) {
            // Create a new date object that's adjusted to UTC
            const now = new Date();
            const utcNow = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
    
            const fridgeItemsRef = collection(db, `users/${user.uid}/fridgeItems`);

            //check for dates where expiryDate is in the future
            const validDatesQuery = query(
                fridgeItemsRef, 
                where("expiryDate", ">=", utcNow),
                where("deletedAt", "==", ""),
                where("usedAt", "==", "")
            );

            //check for dates where expiryDate is null
            const nullDatesQuery = query(
                fridgeItemsRef,
                where("expiryDate", "==", ""),
                where("deletedAt", "==", ""),
                where("usedAt", "==", "")
            );
    
            // Execute both queries
            const [validDatesSnapshot, nullDatesSnapshot] = await Promise.all([
                getDocs(validDatesQuery),
                getDocs(nullDatesQuery)
            ]);
    
            // Combine results
            const ingredients = [
                ...validDatesSnapshot.docs.map(doc => ({
                    name: doc.data().name,
                    quantityLeft: doc.data().quantity - doc.data().quantityUsed,
                    quantityUnit: doc.data().quantityUnit
                })),
                ...nullDatesSnapshot.docs.map(doc => ({
                    name: doc.data().name,
                    quantityLeft: doc.data().quantity - doc.data().quantityUsed,
                    quantityUnit: doc.data().quantityUnit
                }))
            ];
    
            // console.log(ingredients);
            setUserIngredients(ingredients.map(ing => ing.name)); // Store only names for easy comparison
        }
    };
    

    const fetchRecipes = async () => {
        // console.log("fetching all recipes")
        const recipesRef = collection(db, "recipes");
        const snapshot = await getDocs(recipesRef);
        const allRecipes = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        // console.log("recipes", allRecipes);
        setAllRecipes(allRecipes);
    };

    //logic to change state when you adjust filter
    const handleFilterChange = (category, value) => {
        setFilters(prev => {
            const isActive = prev[category].includes(value);
            return {
                ...prev,
                [category]: isActive ? prev[category].filter(item => item !== value) : [...prev[category], value]
            };
        });
    };

    const filterRecipes = () => {
        // console.log("filtering recipes...");
        const filteredRecipes = allRecipes.filter(recipe => {
            const matchesMealType = filters.mealType.length === 0 || (recipe.mealType && filters.mealType.some(type => recipe.mealType.includes(type)));
            const matchesTime = filters.time.length === 0 || (recipe.time && filters.time.includes(recipe.time));
            const matchesCuisine = filters.cuisine.length === 0 || (recipe.cuisine && filters.cuisine.includes(recipe.cuisine));
            const matchesPreferences = filters.foodPreferences.length === 0 || (recipe.preferenceType && filters.foodPreferences.every(pref => recipe.preferenceType.includes(pref)));

            // console.log("Checking recipe:", recipe.name, "with meal types:", recipe.mealType, "against this filter", filters.mealType, "And the result is", matchesMealType);


            return matchesMealType && matchesTime && matchesCuisine && matchesPreferences;
        });
        
        // console.log(filters);
        // console.log("Filtered Recipes:", filteredRecipes.map(r => r.name));
        setRecipes(filteredRecipes);
    };

    // Filter recipes by ingredients
    const filterRecipesByIngredients = () => {
        // console.log("we are filtering now")
        const filteredRecipes = recipes.filter(recipe => {
            const recipeIngredients = recipe.ingredientsDetailed.map(ing => ing.ingredient.toLowerCase());

            // console.log("recipe Ingredients", recipeIngredients, "user ingredients", userIngredients);
            
            const lowerCaseUserIngredients = userIngredients.map(ing => ing.toLowerCase());
            return recipeIngredients.every(ing => lowerCaseUserIngredients.includes(ing));
        });
        // console.log(filteredRecipes);
        setAvailableRecipes(filteredRecipes);
    };

    // loading state
    if (loading) {
        return <div>Loading...</div>; // Render loading state while data is fetching
    }

    // modal for filters
    const toggleModal = () => {
        // console.log("modal state", isModalOpen);
        setIsModalOpen(!isModalOpen);
      };
    

    const FilterSection = ({ category, handleFilterChange, filters }) => {
        return (
            <div className="filter-group mb-3">
                <h4 className="mb-4">{category.title}</h4>
                <div className="position-relative">
                    <i className="bi bi-chevron-compact-left" style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1, opacity: 0.5 }}></i>
                    <div className="d-flex flex-row flex-nowrap overflow-auto align-items-center text-nowrap">
                        {category.options.map(option => (
                            <ToggleButton
                                key={option}
                                id={`${category.key}-${option}`}
                                type="checkbox"
                                variant="outline-primary"
                                className="me-2"
                                checked={filters[category.key].includes(option)}
                                onChange={() => handleFilterChange(category.key, option)}>
                                {option}
                            </ToggleButton>
                        ))}
                    </div>
                    <i className="bi bi-chevron-compact-right" style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1, opacity: 0.5 }}></i>
                </div>
            </div>
        );
    };


    // Generate Recipes
    const numRecipes = 2;
    const recipeNames = allRecipes.map(recipe => recipe.name);


    const generateRecipes = async (runBasedOnIngredients) => {
        setLoadingRecipe(true);
        try {
            const response = await fetch('http://localhost:3001/generate-recipes', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ numRecipes, userIngredients, recipeNames, filters, runBasedOnIngredients})
            });

            if (!response.ok) {
                throw new Error('Failed to generate recipes');
            }

            const newRecipes = await response.json();
            console.log('Generated recipes:', newRecipes);

            fetchRecipes();
            setLoadingRecipe(false);
        } catch (error) {
            console.error('Error generating recipes:', error);
        }
    };

    const filterOptions = [
        { name: 'Breakfast', iconClass: 'fa-regular fa-egg-fried' },
        { name: 'Lunch', iconClass: 'bi bi-basket3-fill' },
        { name: 'Dinner', iconClass: 'fa-sharp fa-regular fa-pan-food' },
        { name: 'Snack', iconClass: 'fa-regular fa-popcorn' },
        { name: 'Dessert', iconClass: 'fa-solid fa-cookie-bite' },
    ];

    return (
        <div className="d-flex flex-column flex-md-row h-lg-full bg-surface-secondary">
            <AppNavbar />
            <div className="h-screen bg-surface-primary flex-grow-1 d-flex flex-column overflow-y-auto">
                <div className="sticky-top">
                    <Header title="Home" />
                    <div className="card mx-2 rounded-0 border-0 sticky-top">
                        {/* <h1 className="h3 ls-tight mx-2">Categories</h1> */}
                        <div className="filter-bar d-flex align-items-center position-relative">
                            <div className="overflow-hidden flex-grow-1 d-flex align-items-center position-relative">
                                <i className="bi bi-chevron-left position-absolute" style={{left: 0, zIndex: 1}} onClick={() => scrollChevron("left", ".fixed-filters")}></i>
                                <div className="fixed-filters overflow-auto flex-grow-1 d-flex align-items-center" style={{ height: '100px' }}>
                                    {filterOptions.map(option => (
                                        <button
                                            key={option.name}
                                            className={`btn btn-link ${filters.mealType.includes(option.name) ? "text-primary" : "text-muted"} mx-2 text-center`}
                                            style={{ height: '80px' }}
                                            onClick={() => handleFilterChange("mealType", option.name)}
                                        >
                                            <i className={option.iconClass}></i>
                                            <div className="mt-2">{option.name}</div>
                                        </button>
                                    ))}
                                </div>
                                <i className="bi bi-chevron-right position-absolute" style={{ right: '10px', zIndex: 1 }} onClick={() => scrollChevron("right", ".fixed-filters")}></i>
                            </div>
                            <div style={{borderLeft: '1px solid #dee2e6', height: '80px', marginRight: '20px'}}></div>
                            <button className="btn btn-outline-primary ms-auto" style={{height: '80px'}} onClick={toggleModal}>
                                <i className="fa-regular fa-sliders ms-2"></i> <b>More Filters</b>
                            </button>
                        </div>

                        <Modal show={isModalOpen} onHide={toggleModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>More Filters</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {filterCategories.map(category => (
                                    <FilterSection
                                        key={category.key}
                                        category={category}
                                        handleFilterChange={handleFilterChange}
                                        filters={filters}
                                    />
                                ))}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={toggleModal}>Close</Button>
                                <Button variant="primary" onClick={toggleModal}>Apply Filters</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
                

                <main className="py-6 bg-surface-primary">


                    <RecipeCardList 
                        recipeArray={availableRecipes} 
                        displayCustomRecipe={true} 
                        title="With ingredients you have" 
                        loadingRecipe={loadingRecipe} 
                        generateRecipes={generateRecipes} 
                        filters={filters} 
                        listClass = "recipe-list-pantry"
                    />

                    <RecipeCardList 
                        recipeArray={recipes} 
                        displayCustomRecipe={false} 
                        title="All Recipes (including those with a few more items)" 
                        loadingRecipe={loadingRecipe} 
                        generateRecipes={generateRecipes} 
                        filters={filters}
                        listClass = "recipe-list-all"
                    />
                </main>


            </div>
            
        </div>
    );
};

export default HomePage;
