// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Blog from './pages/Blog';

import Pantry from './pages/app/Pantry';
import HomePage from './pages/app/Home';
import Profile from './pages/app/Profile';

import Login from './pages/auth/Login';
import Registration from './pages/auth/Registration';


const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/blog" element={<Blog />} />

          <Route path="/app/pantry" element={<Pantry />} />
          <Route path="/app/home" element={<HomePage />} />
          <Route path="/app/profile" element={<Profile />} />

          <Route path="/app/login" element={<Login />} />
          <Route path="/app/register" element={<Registration />} />


        </Routes>
      </div>
    </Router>
  );
};

export default App;
