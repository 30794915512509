import React from 'react';
import Header from '../components/NavHeaders/Header';
import ChefImage from '../assets/chefImage.png'; 

const LandingPage = () => {
  return (
    <div>
      <Header />
      <div className="py-md-32 position-relative">
        <div className="container-lg max-w-screen-xl">
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-1 ms-auto d-none d-lg-block">
              {/* <div className="mb-5 mb-lg-0 w-11/10 position-relative">
                <img src={ChefImage} alt="Chef Cooking" className="svg-fluid" />
              </div> */}

                <div className="col-lg-6 order-lg-1 ms-auto d-none d-lg-block" style={{
                  position: 'relative',
                  height: '500px',
                  width: '500px',
                  borderRadius: '50%',  // Make the image round
                  overflow: 'hidden'
                }}>
                  <img 
                    src={ChefImage} 
                    alt="Chef Cooking" 
                    className="svg-fluid w-100 h-100"  // Bootstrap class to cover the div
                  />
                  {/* Radial gradient fade-out effect using pseudo-element style */}
                  <div style={{
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'radial-gradient(circle, transparent, rgba(255, 255, 255, 0.95) 70%)',
                    pointerEvents: 'none'
                  }}></div>
                  {/* <div className="position-absolute bottom-0 start-72 h-64 w-64 mt-n4 transform translate-y-n1/2 translate-x-n1/2 gradient-bottom-right start-purple-400 end-cyan-500 filter blur-100 rounded-4 p-5"> </div> */}

                </div>

            </div>
            <div className="col-lg-6 order-md-0">
              <h5 className="h5 mb-5 text-uppercase text-warning mb-5">
                The ultimate recipe book
              </h5>
              <h1 className="ls-tight font-bolder display-3 mb-5">
                Cook delicious meals, faster.
              </h1>
              <p className="lead mb-10">
                Recipes & Meal suggestions based on whats in your fridge. Personalized for you. 
              </p>
              <div className="mx-n2">
                <a href="/app/home" className="btn btn-lg btn-primary shadow-sm mx-2 px-lg-8">
                  Get started for Free
                </a>
                <a href="/recipes" className="btn btn-lg btn-neutral mx-2 px-lg-8">
                  Sample Recipes
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
