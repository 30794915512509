import React, { useState, useEffect } from 'react';
import { db, auth } from '../../firebase/firebase';
import { signInAnonymously } from "firebase/auth";
import { doc, setDoc, getDoc, collection, addDoc, getDocs, updateDoc, arrayUnion } from 'firebase/firestore';
import CreatableSelect from 'react-select/creatable';
import AppNavbar from '../../components/NavHeaders/AppNavbar';

const Pantry = () => {
    const [items, setItems] = useState([]);
    const [itemName, setItemName] = useState('');
    const [quantity, setQuantity] = useState('');
    const [validatedIngredients, setValidatedIngredients] = useState([]);
    const [customIngredients, setCustomIngredients] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState('All');

    const [expirationDate, setExpirationDate] = useState('');
    const [editItem, setEditItem] = useState(null);
    const [quantityUsed, setQuantityUsed] = useState(0);

    const [isAdding, setIsAdding] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                ensureUserDocument(user);
                fetchData(user);
            } else {
                signInAnonymously(auth).catch(error => {
                    console.error("Error signing in anonymously:", error);
                });
            }
        });

        async function ensureUserDocument(user) {
            const userDocRef = doc(db, "users", user.uid);
            const userDoc = await getDoc(userDocRef);
            if (!userDoc.exists()) {
                // User doc does not exist, let's create it
                await setDoc(userDocRef, {
                    createdAt: new Date(),
                    phone: null,
                    updatedAt: new Date(),
                    customIngredients: [],
                    name: null
                });

                console.log("User document created:", userDocRef);
            } else {
                setCustomIngredients(userDoc.data().customIngredients || []);
            }
        }

        async function fetchData(user) {
            if (localStorage.getItem('validatedIngredients')) {
                setValidatedIngredients(JSON.parse(localStorage.getItem('validatedIngredients')));
            } else {
                const validatedIngredientsRef = collection(db, 'validatedIngredients');
                const querySnapshot = await getDocs(validatedIngredientsRef);
                const ingredientsList = querySnapshot.docs.map(doc => ({
                    label: doc.data().name,
                    value: doc.id,
                    ...doc.data()
                }));
                localStorage.setItem('validatedIngredients', JSON.stringify(ingredientsList));
                setValidatedIngredients(ingredientsList);
            }

            const fridgeItemsRef = collection(db, `users/${user.uid}/fridgeItems`);
            const querySnapshot = await getDocs(fridgeItemsRef);
            setItems(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));

            setIsLoading(false);
        }

        // Initialize Bootstrap tooltips
        const initializeTooltips = () => {
            var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
            console.log(tooltipTriggerList);
        };

        initializeTooltips();

        return () => unsubscribe(); // Clean up the observer when the component unmounts
    }, []);

    const addItem = async (e) => {
        e.preventDefault();
        setIsAdding(true);
        const user = auth.currentUser;
        if (user) {
            const selectedIngredient = combinedIngredients.find(ingredient => ingredient.name === itemName);
    
            // Define fields with default values if necessary
            const itemType = selectedIngredient.type ? selectedIngredient.type : 'Uncategorized';
            // console.log(selectedIngredient);
            // console.log(itemType);

            const expiryDate = selectedIngredient && selectedIngredient.expirationDays 
                ? new Date(Date.now() + selectedIngredient.expirationDays * 24 * 60 * 60 * 1000) 
                : '';
    
    
            const fridgeItemsRef = collection(db, `users/${user.uid}/fridgeItems`);
    
            try {
                const newDocRef = await addDoc(fridgeItemsRef, {
                    name: itemName,
                    quantity: quantity ? quantity : 1,
                    quantityUsed: 0,
                    type: itemType,
                    addedAt: new Date(),
                    expiryDate: expiryDate,
                    deletedAt: '',
                    usedAt: '',
                });
                setItems([...items, { name: itemName, quantity: quantity ? quantity : 1, type: itemType, id: newDocRef.id, addedAt: new Date() }]);
                setItemName('');
                setQuantity('');
            } catch (error) {
                console.error('Error adding document:', error);
            } finally {
                setIsAdding(false); // Set adding state back to false
            }    
        }
    };

    const handleSelectChange = async (newValue, actionMeta) => {
        if (!newValue) {
            setItemName('');
            setQuantity('');
            return;
        }

        if (actionMeta.action === 'create-option') {
            // If a custom ingredient is created
            const newIngredient = {
                name: newValue.label,
                quantity: 1,
                quantityUnit: 'item',
            };
            setItemName(newValue.label);
            setCustomIngredients([...customIngredients, newIngredient]);

            // Add the new custom ingredient to the user's document
            const user = auth.currentUser;
            if (user) {
                const userDocRef = doc(db, "users", user.uid);
                await updateDoc(userDocRef, {
                    customIngredients: arrayUnion(newIngredient)
                });
            }
        } else {
            setItemName(newValue.label);
            setQuantity(newValue.quantity || ''); 
        }
    };

    const combinedIngredients = [
        ...validatedIngredients.map(ingredient => ({ label: ingredient.name, value: ingredient.name, ...ingredient })),
        ...customIngredients.map(ingredient => ({ label: ingredient.name, value: ingredient.name, ...ingredient }))
    ];

    const filterItemsByCategory = () => {
        const now = new Date();
        return items.filter(item => {
            if (item.deletedAt || item.usedAt) return false;
            if (item.expiryDate && new Date(item.expiryDate.toDate()) < now) return false;
            if (selectedCategory === 'All') return true;
            return item.type === selectedCategory;
        });
    };

    const categories = [
        { label: 'All', icon: '' },
        { label: 'Vegetables', icon: '🥦' },
        { label: 'Fruits', icon: '🍎' },
        { label: 'Grains', icon: '🌾' },
        { label: 'Dairy', icon: '🧀' },
        { label: 'Protein', icon: '🍖' },
        { label: 'Oils', icon: '🛢️' },
        { label: 'Spices', icon: '🌶️' },
        { label: 'Herbs', icon: '🌿' },
        { label: 'Other', icon: '🍽️' },
        { label: 'Uncategorized', icon: '❓' }
    ];

    const handleDelete = async (itemId) => {
        const user = auth.currentUser;
        if (user) {
            const itemDocRef = doc(db, `users/${user.uid}/fridgeItems/${itemId}`);
            await updateDoc(itemDocRef, {
                deletedAt: new Date()
            });
            setItems(items.map(item => item.id === itemId ? { ...item, deletedAt: new Date() } : item));
        }
    };

    const handleEdit = (item) => {
        setEditItem(item);
        setItemName(item.name);
        setQuantity(item.quantity);
        setExpirationDate(item.expiryDate ? new Date(item.expiryDate.seconds * 1000).toISOString().split('T')[0] : '');
        setQuantityUsed(0);
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const user = auth.currentUser;
        if (user && editItem) {
            const itemDocRef = doc(db, `users/${user.uid}/fridgeItems/${editItem.id}`);
            const newQuantity = editItem.quantity - quantityUsed;
            await updateDoc(itemDocRef, {
                name: itemName,
                quantity: newQuantity,
                expiryDate: expirationDate ? new Date(expirationDate) : '',
                usedAt: newQuantity === 0 ? new Date() : ''
            });
            setItems(items.map(item => item.id === editItem.id ? { ...item, name: itemName, quantity: newQuantity,expiryDate: expirationDate ? new Date(expirationDate) : '', usedAt: newQuantity === 0 ? new Date() : '' } : item));
            setEditItem(null);
            setItemName('');
            setQuantity('');
            setExpirationDate('');
            setQuantityUsed(0);
        }
    };

    // Function to get suggested ingredients based on selected category and commonIngredientOrder
    const getSuggestedIngredients = () => {
        return validatedIngredients
            .filter(ingredient => selectedCategory === 'All' || ingredient.type === selectedCategory)
            .sort((a, b) => a.commonIngredientOrder - b.commonIngredientOrder)
            .slice(0, 20);
    };

    const addDefaultIngredient = async (ingredient) => {
        setIsAdding(true); // Set adding state to true
        const user = auth.currentUser;
        if (user) {
            const fridgeItemsRef = collection(db, `users/${user.uid}/fridgeItems`);
            try {
                const newDocRef = await addDoc(fridgeItemsRef, {
                    name: ingredient.name,
                    quantity: ingredient.quantity || 1,
                    quantityUsed: 0,
                    type: ingredient.type || 'Uncategorized',
                    addedAt: new Date(),
                    expiryDate: ingredient.expirationDays ? new Date(Date.now() + ingredient.expirationDays * 24 * 60 * 60 * 1000) : '',
                    deletedAt: '',
                    usedAt: '',
                });
                setItems([...items, { name: ingredient.name, quantity: 1, type: ingredient.type || 'Uncategorized', id: newDocRef.id, addedAt: new Date() }]);
            } catch (error) {
                console.error('Error adding document:', error);
            } finally {
                setIsAdding(false); // Set adding state back to false
            }
        }
    };


    return (
        <div className="d-flex flex-column flex-md-row h-lg-full bg-surface-secondary">
            <AppNavbar />
            <div className="h-screen bg-surface-primary flex-grow-1 d-flex flex-column overflow-y-auto">
                <header className="bg-surface-primary border-bottom pt-6 sticky-top">
                    <div className="container-fluid">
                        <div className="mb-npx">
                            <div className="row align-items-center mb-4">
                                <div className="col-12 d-flex align-items-center justify-content-between flex-wrap">
                                    <div className="d-flex align-items-center">
                                        <a href="/app/home" className="btn btn-light btn-sm me-3">&larr;</a>
                                        <h1 className="h2 mb-0 ls-tight">Pantry</h1>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        {/* <a href="#" className="btn d-inline-flex btn-sm btn-neutral mx-1">
                                            <span className="pe-2">
                                                <i className="fas fa-cog"></i>
                                            </span>
                                            <span>Settings</span>
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="card pt-3 m-4 rounded-0">
                    <div className="px-4">
                        {!isLoading && (
                            <form onSubmit={addItem} className="d-flex align-items-center gap-3 input-group border rounded">
                                <div className="flex-grow-1 d-flex align-items-center">
                                    <CreatableSelect
                                        components={{ DropdownIndicator: () => null }}
                                        isClearable
                                        onChange={handleSelectChange}
                                        options={combinedIngredients}
                                        placeholder="Add an ingredient"
                                        className="flex-grow-1"
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                boxShadow: 'none',
                                                border: 'none',
                                            })
                                        }}
                                    />
                                    <input
                                        type="number"
                                        value={quantity}
                                        onChange={(e) => setQuantity(e.target.value)}
                                        placeholder="Quantity"
                                        required
                                        className="ms-2"
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary" disabled={isAdding}>
                                    {isAdding ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : '+'}
                                </button>
                            </form>
                        )}
                    </div>

                    <div className="px-4 mt-3">
                        <div className="d-flex flex-nowrap gap-2 mb-3 overflow-auto">
                            {categories.map(category => (
                                <button
                                    key={category.label}
                                    className={`btn btn-sm ${selectedCategory === category.label ? 'btn-primary' : 'btn-outline-primary'}`}
                                    onClick={() => setSelectedCategory(category.label)}
                                >
                                    <span className="d-flex align-items-center">
                                        <span className="me-1">{category.icon}</span>
                                        {category.label}
                                    </span>
                                </button>
                            ))}
                        </div>

                        <div className="row">
                            {filterItemsByCategory().map(item => {
                                        const expirationDate = item.expiryDate ? new Date(item.expiryDate.seconds * 1000) : null;
                                        const now = new Date();
                                        const diffTime = expirationDate ? expirationDate - now : null;
                                        const diffDays = diffTime ? Math.ceil(diffTime / (1000 * 60 * 60 * 24)) : null;
                                        
                                        let expirationStatus = 'Not Expired';
                                        let expirationColor = 'green';
                                
                                        if (diffDays !== null) {
                                            if (diffDays < 0) {
                                                expirationStatus = 'Expired';
                                                expirationColor = 'red';
                                            } else if (diffDays <= 3) {
                                                expirationStatus = 'Expires soon';
                                                expirationColor = 'yellow';
                                            }
                                        }
                                return (
                                    <div key={item.id} className="col-12 col-md-6 col-lg-4 mb-3">
                                        <div className="card">
                                            <div className="card-body">
                                            <h5 className="card-title">
                                                {item.name} 
                                                <span className="text-muted"> ({item.quantity - item.quantityUsed}/{item.quantity} remaining)</span>
                                            </h5>
                                                <div className="d-flex align-items-center gap-2">
                                                <span data-bs-toggle="tooltip" title={item.type}>
                                                    {/* <i className="fa-regular fa-layer-group"></i>  */}
                                                    <span className="me-3">{categories.find(category => category.label === item.type)?.icon}</span>
                                                </span>

                                                <span data-bs-toggle="tooltip" title={`Expires on ${expirationDate ? expirationDate.toLocaleDateString() : 'N/A'}`}>
                                                    <i className="bi bi-clock" style={{ color: expirationColor }}></i>  
                                                    <span className="ms-1">{expirationStatus}</span>
                                                </span>

                                                <div className="ms-auto">
                                                    <button className="btn btn-sm btn-primary me-2" onClick={() => handleEdit(item)}>Edit</button>
                                                    <button className="btn btn-sm btn-danger me-2" onClick={() => handleDelete(item.id)}>Delete</button>
                                                </div>

                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            )}
                        </div>
                    </div>
                    
                    <div className="pt-3 m-4 rounded-0" >
                        <div className="d-flex flex-nowrap overflow-auto gap-2">
                            <h5 className="d-flex justify-content-center align-items-center text-nowrap">Tap to add:</h5>
                            {getSuggestedIngredients().map(ingredient => (
                                <div 
                                    key={ingredient.value} 
                                    className="btn btn-sm border d-flex align-items-center text-nowrap justify-content-center"
                                    onClick={() => addDefaultIngredient(ingredient)}
                                >
                                    <span className="text-center">{ingredient.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>


                </div>


            


            </div>


            {editItem && (
                <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Item</h5>
                                <button type="button" className="close" aria-label="Close" onClick={() => setEditItem(null)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={handleEditSubmit}>
                                    <div className="mb-3">
                                        <label className="form-label">Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={itemName}
                                            onChange={(e) => setItemName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3 d-flex">
                                        <div className="ms-2 flex-grow-1">
                                            <label className="form-label">Quantity Used</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={quantityUsed}
                                                onChange={(e) => setQuantityUsed(Math.min(e.target.value, quantity))}
                                                required
                                            />
                                        </div>
                                        <div className="me-2 flex-grow-1">
                                            <label className="form-label">Total Quantity</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={quantity}
                                                onChange={(e) => setQuantity(e.target.value)}
                                                required
                                            />
                                        </div>
                                        
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Expiration Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={expirationDate}
                                            onChange={(e) => setExpirationDate(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Type</label>
                                        <select
                                            className="form-control"
                                            value={editItem?.type || ''}
                                            onChange={(e) => setEditItem({ ...editItem, type: e.target.value })}
                                            required
                                        >
                                            {categories.map(category => (
                                                <option key={category.label} value={category.label}>
                                                    {category.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Save changes</button>
                                    <button type="button" className="btn btn-danger ms-2" onClick={() => handleDelete(editItem.id)}>Delete Item</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default Pantry;
